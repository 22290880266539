<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Certificados</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Certificados da
                  <span>Plataforma</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 pt-4">
                  <!-- CONTEÚDO AQUI -->

                  <div
                    v-if="fastPlataformaCertificados.length"
                    class="row"
                  >
                    <div class="col-12">
                      <button
                        class="btn btn-primary"
                        @click="novoCertificado()"
                      >
                        Novo certificado
                      </button>
                    </div>
                  </div>
                  <div class="row p-4">
                    <carousel
                      v-if="fastPlataformaCertificados.length"
                      :navigation-enabled="true"
                      :per-page="1"
                      :mouse-drag="false"
                      :adjustable-height="true"
                      :pagination-size="20"
                      class="col-sm-12 col-md-12 col-lg-8"
                    >
                      <slide
                        v-for="c in fastPlataformaCertificados"
                        :key="c.id_certificado"
                      >
                        <div class="row">
                          <div class="col-12 text-right">
                            <button
                              class="btn btn-sm btn-danger mb-2"
                              @click="exibeModalExcluirCertificado(c)"
                            >
                              <small>Excluir certificado</small>
                            </button>
                          </div>
                        </div>

                        <div
                          class="card"
                          v-html="c.html_template"
                        />
                      </slide>
                    </carousel>
                    <div
                      v-else
                      class="col-12"
                    >
                      <div class="row">
                        <div class="col-12 pl-0 mb-2">
                          <button
                            class="btn btn-primary"
                            @click="criarCertificado()"
                          >
                            <small>Salvar certificado</small>
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-8">
                          <div class="row">
                            <div class="card w-100">
                              <div data-certificado>
                                <section class="fd-certificado">
                                  <div
                                    class="fd-certificado-main"
                                    style="height: 600px;"
                                  >
                                    <div
                                      class="fd-certificado-simple"
                                      contenteditable="false"
                                    >
                                      <div class="fd-certificado-header pt-4">
                                        <div
                                          class="fd-certificado-header-logo text-center pt-4"
                                          v-html="fastCertificadoNovo.logo"
                                        />
                                        <div
                                          class="fd-certificado-header-titulo"
                                          v-text="fastCertificadoNovo.titulo"
                                        />
                                      </div>
                                      <div class="fd-certificado-body">
                                        <div
                                          class="fd-certificado-body-descricao"
                                          v-html="fastCertificadoNovo.descricao"
                                        />
                                        <div
                                          class="fd-certificado-body-info-curso"
                                          v-html="fastCertificadoNovo.curso_info"
                                        />
                                      </div>
                                      <div class="fd-certificado-footer pb-4">
                                        <div class="fd-certificado-footer-content pb-4">
                                          <div
                                            class="col-sm-12 col-md-12 col-lg-6"
                                            isloaded="true"
                                          >
                                            <div class="text-center">
                                              <div v-html="fastCertificadoNovo.assinatura1" />
                                              <hr class="mt-0 mb-0">
                                              <p
                                                v-text="fastCertificadoNovo.assinatura1Responsavel"
                                              />
                                            </div>
                                          </div>
                                          <div
                                            class="col-sm-12 col-md-12 col-lg-6"
                                            isloaded="true"
                                          >
                                            <div class="text-center">
                                              <div v-html="fastCertificadoNovo.assinatura2" />
                                              <hr class="mt-0 mb-0">
                                              <p
                                                v-text="fastCertificadoNovo.assinatura2Responsavel"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <label class="font-weight-bold">Assinatura 1</label>
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-4">
                                      <button
                                        class="btn btn-sm btn-primary ml-2"
                                        @click="showModal('modalAssinatura1Digital')"
                                      >
                                        Digital
                                      </button>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-8">
                                      <input
                                        id="assinatura1"
                                        type="file"
                                        class="custom-file-input"
                                        accept="image/*"
                                        @change="setImageAssinatura1"
                                      >
                                      <label
                                        class="custom-file-label"
                                        for="assinatura1"
                                      >Imagem...</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                                  <label class="font-weight-bold">Responsável</label>
                                  <input
                                    v-model="fastCertificadoNovo.assinatura1Responsavel"
                                    type="text"
                                    class="form-control"
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                  <label class="font-weight-bold">Assinatura 2</label>
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-4">
                                      <button
                                        class="btn btn-sm btn-primary ml-2"
                                        @click="showModal('modalAssinatura2Digital')"
                                      >
                                        Digital
                                      </button>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-8">
                                      <input
                                        id="assinatura1"
                                        type="file"
                                        class="custom-file-input"
                                        accept="image/*"
                                        @change="setImageAssinatura2"
                                      >
                                      <label
                                        class="custom-file-label"
                                        for="assinatura2"
                                      >Imagem...</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                                  <label class="font-weight-bold">Responsável</label>
                                  <input
                                    v-model="fastCertificadoNovo.assinatura2Responsavel"
                                    type="text"
                                    class="form-control"
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <div class="row">
                            <div class="col-12">
                              <label class="font-weight-bold">Logo do certificado</label>
                              <div class="row pl-3 pr-3">
                                <div class="col-12">
                                  <input
                                    id="logo"
                                    type="file"
                                    class="custom-file-input"
                                    accept="image/*"
                                    @change="setImageLogo"
                                  >
                                  <label
                                    class="custom-file-label"
                                    for="logo"
                                  >Imagem...</label>
                                </div>
                                <label class="message-field-label mt-2">Dimensões sugeridas: 360 x 151 pixels</label>
          
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-12">
                              <label class="font-weight-bold">Título</label>
                              <input
                                v-model="fastCertificadoNovo.titulo"
                                type="text"
                                class="form-control"
                              >
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-12">
                              <label class="font-weight-bold">Descrição</label>
                              <quill-editor
                                v-model="fastCertificadoNovo.descricao"
                                :options="editorOption"
                              />
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-12">
                              <label class="font-weight-bold">Informações do curso</label>
                              <quill-editor
                                v-model="fastCertificadoNovo.curso_info"
                                :options="editorOption"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pl-0 mb-2 mt-4 text-center">
                          <button
                            class="btn btn-primary"
                            @click="criarCertificado()"
                          >
                            <small>Salvar certificado</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <vue-html2pdf
                    ref="html2Pdf"
                    :show-layout="false"
                    :enable-download="true"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    filename="hee hee"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="landscape"
                    pdf-content-width="800px"
                    @progress="onProgress($event)"
                    @hasStartedGeneration="hasStartedGeneration()"
                    @hasGenerated="hasGenerated($event)"
                  >
                    <section slot="pdf-content">
                      <!-- PDF Content Here -->
                    </section>
                  </vue-html2pdf>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <modal
      name="modalExcluirCertificado"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir certificado da plataforma?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirCertificado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirCertificado()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAssinatura1Digital"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Insira sua assinatura</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAssinatura1Digital')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div> 
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <vue-signature-pad
                ref="signaturePad1"
                style="height: 100px"
                class="shadow rounded"
              />
              <div class="d-flex justify-content-center mt-2">
                <button
                  class="btn btn-sm btn-primary mr-1"
                  @click="saveAssinatura1"
                >
                  <small>Salvar</small>
                </button>
                <button
                  class="btn btn-sm btn-danger ml-1"
                  @click="undoAssinatura1"
                >
                  <small>Desfazer</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAssinatura2Digital"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Insira sua assinatura</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAssinatura2Digital')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <vue-signature-pad
                ref="signaturePad2"
                style="height: 100px"
                class="shadow rounded"
              />
              <div class="d-flex justify-content-center mt-2">
                <button
                  class="btn btn-sm btn-primary mr-1"
                  @click="saveAssinatura2"
                >
                  <small>Salvar</small>
                </button>
                <button
                  class="btn btn-sm btn-danger ml-1"
                  @click="undoAssinatura2"
                >
                  <small>Desfazer</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAssinatura1Image"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row">
        <div class="col-12 pt-4 text-center">
          <h4>Recorte a imagem</h4>
        </div>
        <div
          v-if="fastCertificadoNovo.assinatura1"
          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
        >
          <vue-cropper
            ref="cropper"
            :src="fastCertificadoNovo.assinatura1"
            :auto-crop-area="1"
          />
          <div class="text-center mt-2">
            <a
              class="btn btn-primary"
              href="#"
              role="button"
              @click.prevent="cropImageAssinatura1()"
            >Recortar</a>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAssinatura2Image"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row">
        <div class="col-12 pt-4 text-center">
          <h4>Recorte a imagem</h4>
        </div>
        <div
          v-if="fastCertificadoNovo.assinatura2"
          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
        >
          <vue-cropper
            ref="cropper"
            :src="fastCertificadoNovo.assinatura2"
            :auto-crop-area="1"
          />
          <div class="text-center mt-2">
            <a
              class="btn btn-primary"
              href="#"
              role="button"
              @click.prevent="cropImageAssinatura2()"
            >Recortar</a>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalLogo"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row">
        <div class="col-12 pt-4 text-center">
          <h4>Recorte a imagem</h4>
        </div>
        <div
          v-if="fastCertificadoNovo.logo"
          class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
        >
          <vue-cropper
            ref="cropper"
            :src="fastCertificadoNovo.logo"
            :auto-crop-area="1"
          />
          <div class="text-center mt-2">
            <a
              class="btn btn-primary"
              href="#"
              role="button"
              @click.prevent="cropImageLogo()"
            >Recortar</a>
          </div>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme
import VueHtml2pdf from "vue-html2pdf"; // https://github.com/kempsteven/vue-html2pdf
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { VueSignaturePad } from 'vue-signature-pad';

export default {
  name: "HomeInternoAdminPersonalizarCertificados",
  components: {
    Carousel,
    Slide,
    VueHtml2pdf,
    quillEditor,
    VueCropper,
    'vue-signature-pad': VueSignaturePad
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Certificados
      fastPlataformaCertificados: [],
      fastCertificadoEdicao: {
        html_template: "",
        id_certificado: "",
        id_plataforma: 0,
      },
      fastCertificadoNovo: {
        html_template: "",
        id_certificado: 0,
        id_plataforma: this.$route.params.id_plataforma,
        logo:
          '<img src="' +
          this.retornaSessao(settings.fastSessaoPlataforma).logo_plataforma +
          '">',
        titulo: "Certificado de participação",
        descricao:
          '<div class="fd-certificado-body-descricao"><p>Certificamos que</p><h1>[nome_aluno]</h1><p>Concluiu o curso online</p><strong>[nome_curso]</strong></div>',
        curso_info:
          "<p>Carga horária estimada em [carga_horaria] com [data_inicio] e concluído em [data_conclusao].</p>",
        assinatura1: "Assinatura 1",
        assinatura1Responsavel: "Diretor",
        assinatura2Responsavel: "Coordenador",
        assinatura2: "Assinatura 2",
      },
      // Editor de texto
      editorOptionImageOnly: {
        modules: {
          toolbar: [["image"]],
        },
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastPlataformaCertificados();
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFastPlataformaCertificados() {
      this.fastPlataformaCertificados = [];
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_certificado/lista?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaCertificados = obj;
        }
        //console.log("getFastPlataformaCertificados", this.fastPlataformaCertificados);

        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    undoAssinatura1() {
      this.$refs.signaturePad1.undoSignature();
      this.fastCertificadoNovo.assinatura1 = "";
    },
    saveAssinatura1() {
      const { isEmpty, data } = this.$refs.signaturePad1.saveSignature();
      console.log(isEmpty);
      console.log(data);
      this.fastCertificadoNovo.assinatura1 =
        '<img class="img-fluid" src="' + data + '">';
      this.hideModal("modalAssinatura1Digital");
    },
    undoAssinatura2() {
      this.$refs.signaturePad2.undoSignature();
      this.fastCertificadoNovo.assinatura2 = "";
    },
    saveAssinatura2() {
      const { isEmpty, data } = this.$refs.signaturePad2.saveSignature();
      console.log(isEmpty);
      console.log(data);
      this.fastCertificadoNovo.assinatura2 =
        '<img class="img-fluid" src="' + data + '">';
      this.hideModal("modalAssinatura2Digital");
    },
    exibeModalExcluirCertificado(certificado) {
      this.fastCertificadoEdicao = certificado;
      this.showModal("modalExcluirCertificado");
    },
    async excluirCertificado() {
      //console.log(this.fastCertificadoEdicao);
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_certificado/exclui",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.fastCertificadoEdicao)
          )
        );
        let json = await resp.json();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Certificado excluído",
        });
        this.hideModal("modalExcluirCertificado");
        this.getFastPlataformaCertificados();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    novoCertificado() {
      //console.log(this.fastPlataformaCertificados.length);
      /*if (this.fastPlataformaCertificados.length == 1 && this.fastPlataformaCertificados[0].id_certificado == 0) {
        this.fastPlataformaCertificados[0].html_template = this.fastCertificadoNovo.html_template;
      }*/
      this.fastPlataformaCertificados = [];
    },
    async criarCertificado() {
      let html_template = `
        <div class="card w-100">
          <div data-certificado>
            <section class="fd-certificado">
              <div class="fd-certificado-main" style="min-height: 600px;">
                <div class="fd-certificado-simple" contenteditable="false">
                  <div class="fd-certificado-header pt-4">
                    <div class="fd-certificado-header-logo text-center pt-4">${this.fastCertificadoNovo.logo}</div>
                    <div class="fd-certificado-header-titulo">${this.fastCertificadoNovo.titulo}</div>
                  </div>
                  <div class="fd-certificado-body">
                    <div class="fd-certificado-body-descricao">${this.fastCertificadoNovo.descricao}</div>
                    <div class="fd-certificado-body-info-curso">${this.fastCertificadoNovo.curso_info}</div>
                  </div>
                  <div class="fd-certificado-footer pb-4">
                    <div class="fd-certificado-footer-content pb-4">
                      <div class="col-sm-12 col-md-12 col-lg-6" isloaded="true">
                        <div class="text-center">
                          <div>${this.fastCertificadoNovo.assinatura1}</div>
                          <hr class="mt-0 mb-0" />
                          <p>${this.fastCertificadoNovo.assinatura1Responsavel}</p>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6" isloaded="true">
                        <div class="text-center">
                          <div>${this.fastCertificadoNovo.assinatura2}</div>
                          <hr class="mt-0 mb-0" />
                          <p>${this.fastCertificadoNovo.assinatura2Responsavel}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      `;

      this.fastCertificadoNovo.html_template = html_template;
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_certificado/insere",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastCertificadoNovo))
        );
        let json = await resp.json();
        this.$store.state.fastCarregando = false;
        if (json.length) {
          this.fastCertificadoNovo = {
            html_template: "",
            id_certificado: 0,
            id_plataforma: this.$route.params.id_plataforma,
            logo:
              '<img src="' +
              this.retornaSessao(settings.fastSessaoPlataforma)
                .logo_plataforma +
              '">',
            titulo: "Certificado de participação",
            descricao:
              '<div class="fd-certificado-body-descricao"><p>Certificamos que</p><h1>[nome_aluno]</h1><p>Concluiu o curso online</p><strong>[nome_curso]</strong></div>',
            curso_info:
              "<p>Carga horária estimada em [carga_horaria] com [data_inicio] e concluído em [data_conclusao].</p>",
            assinatura1: "Assinatura 1",
            assinatura1Responsavel: "Diretor",
            assinatura2Responsavel: "Coordenador",
            assinatura2: "Assinatura 2",
          };
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Certificado inserido com sucesso",
          });
          this.hideModal("modalExcluirCertificado");
          this.getFastPlataformaCertificados();
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 10000,
            speed: 1000,
            title: "Erro",
            text: "Erro ao inserir certificado",
          });
        }
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false;
      }
    },
    setImageLogo(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalLogo");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setImageAssinatura1(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalAssinatura1Image");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    setImageAssinatura2(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size / 1024 > 1024) {
        alert("Não utilize imagens acima de 1MB");
        return;
      }

      this.showModal("modalAssinatura2Image");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cropImageLogo() {
      this.fastCertificadoNovo.logo =
        '<img class="img-fluid" src="' +
        this.$refs.cropper.getCroppedCanvas().toDataURL() +
        '">';
      this.hideModal("modalLogo");
    },
    cropImageAssinatura1() {
      this.fastCertificadoNovo.assinatura1 =
        '<img class="img-fluid" src="' +
        this.$refs.cropper.getCroppedCanvas().toDataURL() +
        '">';
      this.hideModal("modalAssinatura1Image");
    },
    cropImageAssinatura2() {
      this.fastCertificadoNovo.assinatura2 =
        '<img class="img-fluid" src="' +
        this.$refs.cropper.getCroppedCanvas().toDataURL() +
        '">';
      this.hideModal("modalAssinatura2Image");
    },
  },
};
</script>

<style scope>
/* Certificado */

.fd-certificado-main {
  height: auto !important;
}
.fd-certificado-main img {
  max-width: 100% !important;
}
.fd-certificado-content {
  grid-template-columns: 100% !important;
}
.fd-certificado-footer-content p {
  margin: 0;
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
</style>
